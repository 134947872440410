<template>
    <div>
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.sendEmail')"
            :title="$t('messages.sendEmail')"
            @submitPressed="submitPressed"
        />
        <div class="content-box">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12">
                        <b-form-group
                            class="row"
                            :label="$t('forms.branches')"
                            label-for="h-branches"
                            label-cols-md="2"
                            content-cols-lg="4"
                        >
                            <v-select
                                id="h-branches"
                                v-model="email.branches"
                                :options="branches"
                                taggable
                                multiple
                                push-tags
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.stations')"
                            label-for="h-stations"
                            label-cols-md="2"
                            content-cols-lg="4"
                        >
                            <v-select
                                id="h-stations"
                                v-model="email.stations"
                                :options="stations"
                                taggable
                                multiple
                                push-tags
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.departments')"
                            label-for="h-departments"
                            label-cols-md="2"
                            content-cols-lg="4"
                        >
                            <v-select
                                id="h-departments"
                                v-model="email.departments"
                                :options="departments"
                                taggable
                                multiple
                                push-tags
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.notificationEmails')"
                            label-for="notificationEmails"
                            label-cols-md="2"
                            content-cols-lg="4"
                        >
                            <v-select
                                id="notificationEmails"
                                v-model="email.notificationEmails"
                                taggable
                                multiple
                                push-tags
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.category')"
                            label-for="category"
                            label-cols-md="2"
                            content-cols-lg="4"
                        >
                            <v-select id="notificationEmails" :options="categories" v-model="email.category"/>
                            <div
                                class="input-errors"
                                v-for="error of v$.email.category.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :class="{ row, error: v$.email.subject.$errors.length }"
                            :label="$t('forms.subject')"
                        >
                            <b-form-input
                                v-model="email.subject"
                                id="h-subject"
                                type="text"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.email.subject.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>
                        <b-form-group
                            :class="{ row, error: v$.email.message.$errors.length }"
                            :label="$t('forms.message')" id="input-group-2" class="mb-0">
                            <tinymce
                                content=""
                                @editorContentChanged="updateContent"
                            />
                            <div
                                class="input-errors"
                                v-for="error of v$.email.message.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import helper from "@/libs/helper";

export default {
    components: {
        TitleBar,
        vSelect,
        tinymce
    },
    mixins: [ResourceUtils, ListUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            email: {
                category: {required},
                subject: {required},
                message: {required},
            },
        };
    },
    data() {
        return {
            email: {
                branches: [],
                departments: [],
                stations: [],
                notificationEmails: [],
                category: null,
                subject: null,
                message: null
            },
            branches: [],
            departments: [],
            stations: [],
            categories: [
                'Operational Topic',
                'Safety Alert',
                'IT Update'
            ],
            selectedBranches: [],
            selectedDepartments: [],
            selectedStations: [],
        };
    },
    created() {
        this.getResourceList(this.$Branches, this.branches)
        this.getResourceList(this.$Stations, this.stations)
        this.getResourceList(this.$Departments, this.departments)
    },
    methods: {
        updateContent(content) {
            this.email.message = content;
        },
        success() {
            this.$router.push(`/emails`);
        },
        error() {
            this.$emit("clearAction");
        },
        selected(values) {
            const list = [];
            values.forEach(element => {
                list.push({
                    label: `${element.name}`,
                    id: element['@id'],
                })
            })
            return list;
        },
        async submitPressed() {
            const isValid = await this.v$.$validate();
            this.$emit("clearAction");
            if (isValid) {
                const body = {
                    templateName: 'COMMON_EMAIL',
                    placeholders: [
                        {
                            value: this.email.category + ' ' + helper.formatDate(new Date()) + ': ' + this.email.subject,
                            name: '{subject}'
                        },
                        {
                            value: this.email.message,
                            name: '{body}'
                        }
                    ]
                }
                body.branches = this.email.branches.map(function (branch) {
                    return branch.numberId
                });
                body.stations = this.email.stations.map(function (station) {
                    return station.numberId
                });
                body.departments = this.email.departments.map(function (department) {
                    return department.numberId
                });
                body.recipients = this.email.notificationEmails.map(function (recipient) {
                    return {email: recipient}
                });
                this.create(
                    this.$Emails,
                    body,
                    'Email was successfully send',
                    null,
                    this.success,
                    this.error
                );
            }
        },
    },
}
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
